<template>
  <div class="container">
    <div v-html="html" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TermsOfUse",
  data() {
    return {
      html: "Okay"
    };
  },
  async created() {
    const html = await axios.get("/html/TermsOfUse.html");
    this.html = html.data;
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  div {
    height: 100%;
    width: 100%;
  }
}
</style>
