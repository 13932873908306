<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="!submitting ? $emit('close') : 0" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <template v-if="step == 1">
                <span class="title">What song would you like to protect?</span>

                <div class="grid grid-cols-1 gap-4">
                  <button
                    @click="
                      () => {
                        unreleasedSong = true;
                        step = 2;
                      }
                    "
                    class="inline-flex flex-col items-start gap-2 px-4 py-4 text-gray-900 bg-white rounded-md cursor-pointer gap-y-2 "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-music-cog"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                      <path d="M9 17v-13h10v7.5" />
                      <path d="M9 8h10" />
                      <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                      <path d="M19.001 15.5v1.5" />
                      <path d="M19.001 21v1.5" />
                      <path d="M22.032 17.25l-1.299 .75" />
                      <path d="M17.27 20l-1.3 .75" />
                      <path d="M15.97 17.25l1.3 .75" />
                      <path d="M20.733 20l1.3 .75" />
                    </svg>
                    <span class="text-lg font-semibold ">
                      An unreleased song
                    </span>
                    <span class="text-left text-gray-700"
                      >Choose this option if you have a song that hasn’t been
                      released yet and you want to secure its rights before
                      releasing it.
                    </span>
                  </button>
                  <button
                    @click="
                      () => {
                        unreleasedSong = false;
                        step = 2;
                      }
                    "
                    class="inline-flex flex-col items-start gap-2 px-4 py-4 text-gray-900 bg-white rounded-md cursor-pointer gap-y-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-music-bolt"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                      <path d="M9 17v-13h10v8" />
                      <path d="M9 8h10" />
                      <path d="M19 16l-2 3h4l-2 3" />
                    </svg>
                    <span class="text-lg font-semibold ">
                      An existing release
                    </span>
                    <span class="text-left text-gray-700"
                      >Select this option if your song has already been released
                      to the public and you need to protect its existing rights.
                    </span>
                  </button>
                </div>
              </template>

              <template v-if="step == 2">
                <span class="title">{{
                  unreleasedSong
                    ? "Add an unreleased song"
                    : "Add an existing release"
                }}</span>
                <div class="inputs">
                  <div class="form-group">
                    <input
                      v-model="spotifySearch"
                      id="song"
                      type="text"
                      class="text-input"
                      :placeholder="
                        unreleasedSong
                          ? 'Type the name of your song'
                          : 'Search Song Name'
                      "
                    />
                  </div>
                </div>

                <p
                  v-if="!unreleasedSong"
                  class="text-white pb-3 text-left w-full"
                >
                  <template v-if="spotifySearch">
                    <span
                      >Results for
                      <span class="font-bold">{{ spotifySearch }}</span></span
                    >
                  </template>
                  <template v-else>
                    <span>Search for a song by name, co-artist, or album.</span>
                  </template>
                </p>
                <ul
                  v-if="!unreleasedSong"
                  class="flex flex-col w-full max-w-lg gap-2 mb-5 overflow-y-auto bg-white border rounded-lg h-96 bg-opacity-5 max-h-80"
                  style="padding: 10px;"
                >
                  <li
                    @click="selectSpotifySong(pSong)"
                    class="rounded-lg cursor-pointer song-tile"
                    :style="
                      `background-image: url(${pSong.album.images[0].url}); background-size: cover; background-position: center; height: 100px;`
                    "
                    v-for="(pSong, i) in songResults"
                    :key="i"
                  >
                    <span
                      class="flex items-center justify-between w-full h-full px-4 text-sm text-gray-300 transition-all duration-300 scale-105"
                      style="background-color: rgba(0, 0, 0, 0.3); backdrop-filter: blur(6px);"
                    >
                      {{ pSong.name }}
                      <svg
                        v-if="
                          spotifySelectedSong &&
                            pSong.id === spotifySelectedSong.id
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        style="color: #FF035A;"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                          stroke-width="0"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </li>
                </ul>
                <!-- 
                <div class="pt-10 buttons">
                  <button class="primary outline dark" :disabled="submitting" @click="$emit('close')">
                    Cancel
                  </button>
                  <button class="primary" @click="add" v-if="!song.id"
                    :disabled="!song.name || submitting || searching">
                    {{ submitting ? "Submitting..." : `Create` }}
                  </button>
                  <button class="primary" @click="add" v-else :disabled="!song.name || submitting || searching">
                    {{
                submitting
                  ? "Submitting..."
                  : `Add ${artist.name} to ${song.name}`
              }}
                  </button>
                </div> -->

                <div class="pt-10 buttons">
                  <button
                    class="primary outline dark"
                    :disabled="submitting"
                    @click="step = 1"
                  >
                    Back
                  </button>

                  <button
                    class="primary"
                    @click="step = 3"
                    :disabled="!secondStepValid"
                  >
                    Continue
                  </button>
                </div>
                <!-- <div class="flex items-center justify-center w-full max-w-xs gap-2 py-4">
                  <span class="flex-1 h-px bg-gray-400"></span>
                  <span class="text-sm text-gray-300">OR</span>
                  <span class="flex-1 h-px bg-gray-400"></span>
                </div>
                <div class="">
                  <button class="primary" @click="() => {
                importMode = true;
                song = {};
              }
                " :disabled="submitting">
                    Import Previous Song
                  </button>
                </div> -->
              </template>
              <template v-if="step == 3">
                <span class="title">How would you like to proceed?</span>

                <div class="grid grid-cols-1 gap-4">
                  <button
                    @click="
                      () => {
                        newDeal = true;
                        newFlowAdd();
                        // add logic here
                      }
                    "
                    class="inline-flex flex-col items-start gap-2 px-4 py-4 text-gray-900 bg-white rounded-md cursor-pointer gap-y-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="submitting"
                  >
                    <svg
                      v-if="!submitting"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-square-rounded-plus"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"
                      />
                      <path d="M15 12h-6" />
                      <path d="M12 9v6" />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-loader-2 animate-spin"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 3a9 9 0 1 0 9 9" />
                    </svg>
                    <span class="text-lg font-semibold ">
                      Create a new deal structure
                    </span>
                    <span class="text-left text-gray-700"
                      >Use this option to create a completely new deal structure
                      from scratch.
                    </span>
                  </button>
                  <button
                    @click="
                      () => {
                        newDeal = false;
                        step = 4;
                      }
                    "
                    class="inline-flex flex-col items-start gap-2 px-4 py-4 text-gray-900 bg-white rounded-md cursor-pointer gap-y-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="submitting"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-database-import"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3"
                      />
                      <path
                        d="M4 6v6c0 1.657 3.582 3 8 3c.856 0 1.68 -.05 2.454 -.144m5.546 -2.856v-6"
                      />
                      <path
                        d="M4 12v6c0 1.657 3.582 3 8 3c.171 0 .341 -.002 .51 -.006"
                      />
                      <path d="M19 22v-6" />
                      <path d="M22 19l-3 -3l-3 3" />
                    </svg>
                    <span class="text-lg font-semibold ">
                      Copy an existing deal structure
                    </span>
                    <span class="text-left text-gray-700"
                      >Choose this if you want to duplicate a current deal
                      structure, which you can then modify as needed.
                    </span>
                  </button>
                </div>
              </template>
              <template v-if="step == 4">
                <span class="title">Search for an existing deal</span>
                <div class="inputs">
                  <div class="form-group">
                    <input
                      v-model="search"
                      id="song"
                      type="text"
                      class="text-input"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <ul
                  class="w-full h-64 max-w-sm flex flex-col gap-0.5 mb-5 rounded-lg border bg-white bg-opacity-5 max-h-64 overflow-y-auto"
                  style="padding: 10px;"
                >
                  <li
                    @click="selectSong(pSong)"
                    class="px-2 py-2 rounded-lg cursor-pointer hover:bg-white hover:bg-opacity-5"
                    v-for="(pSong, i) in previousSongsFiltered"
                    :key="i"
                  >
                    <span
                      class="flex items-center justify-between w-full text-sm text-gray-300"
                    >
                      {{ pSong.name }}
                      <svg
                        v-if="pSong.id === song.id"
                        xmlns="http://www.w3.org/2000/svg"
                        style="color: #FF035A;"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#000000"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                          stroke-width="0"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </li>
                </ul>
                <div class="buttons" style="margin-top: 20px;">
                  <button
                    class="primary outline dark"
                    :disabled="submitting"
                    @click="
                      () => {
                        step = 3;
                        newDeal = false;
                        song.id = null;
                      }
                    "
                  >
                    Back
                  </button>
                  <button
                    class="primary"
                    style="max-width: 250px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                    @click="newFlowAdd"
                    :disabled="!song.id || submitting || searching"
                  >
                    {{
                      submitting
                        ? "Submitting..."
                        : song.name
                        ? `Duplicate ${song.name || ""}`
                        : "Choose contract to duplicate"
                    }}
                  </button>
                </div>
              </template>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import axios from "axios";
import gql from "graphql-tag";
import ADD_SONG from "@/api/mutations/ADD_SONG.gql";
import ADD_COLLABORATOR from "@/api/mutations/ADD_COLLABORATOR.gql";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import SEARCH_SONGS from "@/api/queries/SEARCH_SONGS.gql";
import UPDATE_SONG from "@/api/mutations/UPDATE_SONG.gql";
import UPDATE_DEAL from "@/api/mutations/UPDATE_DEAL.gql";

export default {
  name: "AddSong",
  components: {
    ModalCloseIcon
  },
  props: {
    myArtist: {
      type: Object,
      required: true
    },
    artist: {
      type: Object,
      required: false,
      default: () => ({})
    },
    addNewRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      song: {
        name: "",
        id: null,
        spotify_id: null
      },
      offset: 0,
      results: [],
      total: null,
      fetchingMore: false,
      selected: {},
      submitting: false,
      addedSong: null,
      previousSongs: [],
      importMode: false,
      search: "",
      step: 1,
      unreleasedSong: false,
      songResults: [],
      spotifySelectedSong: null,
      spotifySearch: "",
      spotifyOffset: 0,
      spotifyFetchingMore: false,
      newDeal: false
    };
  },
  watch: {
    searchData(data) {
      this.results = data.results;
      this.total = data.total;
    },
    fields() {
      if (this.searchDisabled) return;
      if (this.song.id) delete this.song.id;
    },
    spotifySearch(value) {
      if (this.unreleasedSong) {
        this.song.name = value;
        return;
      }

      if (value.length < 3) return;
      this.songResults = [];
      this.spotifySelectedSong = null;
      this.spotifyOffset = 0;
      this.getArtistSongs(value);
    },
    step(current) {
      if (current === 1) {
        this.unreleasedSong = false;
        this.song = {
          name: "",
          id: null,
          spotify_id: null
        };
      }
      if (current === 3) {
        this.newDeal = false;
      }
    }
  },
  computed: {
    valid() {
      const {
        song: { id, name },
        artist
      } = this;
      if (id && name) return true;
      if (name && this.addingNew && artist.id) return true;
      return false;
    },
    fields() {
      return JSON.stringify(this.song);
    },
    fieldsEmpty() {
      return !this.song.name;
    },
    searchDisabled() {
      if (this.addNewRequired) return true;
      const { fieldsEmpty, isSelectionActive, addingNew } = this;
      return fieldsEmpty || isSelectionActive || addingNew;
    },
    searching() {
      return this.$apollo.queries.searchData.loading;
    },
    resultsAvailable() {
      return (
        this.results &&
        this.results.length &&
        (!this.searching || this.fetchingMore) &&
        !this.fieldsEmpty &&
        !this.searchDisabled
      );
    },
    canFetchMore() {
      const { results, resultsAvailable, total, searching } = this;
      return resultsAvailable && results.length < total && !searching;
    },
    isSelectionActive() {
      return JSON.stringify(this.song) === this.selected;
    },
    previousSongsFiltered() {
      return this.previousSongs.filter(el =>
        el.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    secondStepValid() {
      if (this.unreleasedSong)
        return (
          this.song.name &&
          this.song.name.length > 3 &&
          this.song.name.length < 100
        );
      else
        return (
          this.spotifySelectedSong?.name &&
          this.spotifySelectedSong?.name.length > 3 &&
          this.spotifySelectedSong?.id !== null
        );
    }
  },
  methods: {
    selectSong(song) {
      const originalSong = this.song;
      this.song = this.formatSongsDuplicate([song])[0];
      this.song.spotify_id = originalSong.spotify_id;
      this.song.name = originalSong.name;
    },
    selectSpotifySong(song) {
      this.spotifySelectedSong = song;
    },
    loadMore() {
      this.offset += 10;
      this.fetchingMore = true;
    },
    selectResult(data) {
      this.selected = JSON.stringify(data);
      this.song = data;
    },
    isMe(artist) {
      return artist.id === this.$store.getters["account/getArtistId"];
    },
    checkChargesEnabled(artist) {
      return !!((artist.artist_users || [])[0]?.user || {})?.charges_enabled;
    },
    getViewerInfo(collaborators) {
      const { recording, publishing } = collaborators.find(
        el => el.isMe
      ).percentage;
      return {
        isHost: collaborators[0].isMe,
        myRPM: [recording, publishing].join("/")
      };
    },
    getHostPercentage(guests = []) {
      let resp = {
        recording: 100,
        publishing: 100
      };
      guests.forEach(guest => {
        Object.keys(guest.percentage).forEach(el => {
          if (resp[el]) resp[el] -= guest.percentage[el];
        });
      });
      return resp;
    },
    formatArtist(artist) {
      if (artist.name) return artist;
      let name = artist.artist_users[0].user?.email || "";
      return { ...artist, name };
    },
    formatSongs(songs) {
      return songs.map(({ id, name, splits, host: hostArtist }) => {
        let host = {
          info: this.formatArtist(hostArtist),
          isHost: true,
          isMe: this.isMe(hostArtist)
        };

        const details = { id, name };
        const { split_details = [] } = splits[0] || {};

        const guests = split_details.flatMap(el => [
          {
            status: el.deal.status,
            info: this.formatArtist(el.deal.artistTo),
            services: el.deal.services || [],
            fee_status: el.deal.fee_status,
            fee: el.deal.fee,
            percentage: el.percentage,
            deal_id: el.deal_id,
            isMe: this.isMe(el.deal.artistTo),
            chargesEnabled: this.checkChargesEnabled(el.deal.artistTo)
          }
        ]);

        host.percentage = this.getHostPercentage(guests);

        return {
          ...details,
          host_id: hostArtist.id,
          collaborators: [host, ...guests],
          ...this.getViewerInfo([host, ...guests])
        };
      });
    },
    formatSongsDuplicate(songs) {
      return songs.map(({ id, name, splits, host: hostArtist }) => {
        let host = {
          info: this.formatArtist(hostArtist),
          isHost: true,
          isMe: this.isMe(hostArtist)
        };

        const details = { id, name };
        const { split_details = [] } = splits[0] || {};

        const guests = split_details.flatMap(el => [
          {
            status: "pending",
            info: this.formatArtist(el.deal.artistTo),
            services: el.deal.services || [],
            fee_status: null,
            fee: el.deal.fee,
            percentage: el.percentage,
            deal_id: el.deal_id,
            isMe: this.isMe(el.deal.artistTo),
            chargesEnabled: this.checkChargesEnabled(el.deal.artistTo)
          }
        ]);

        host.percentage = this.getHostPercentage(guests);

        return {
          ...details,
          host_id: hostArtist.id,
          collaborators: [host, ...guests],
          ...this.getViewerInfo([host, ...guests])
        };
      });
    },
    formatSplit() {
      const { artist: collaborator } = this;
      const { id, collaborators, host_id } = this.addedSong || this.song;

      const proposer = collaborators.find(el => el.isMe).info.id;
      const split = {
        song_id: id,
        artist_id_proposer: proposer
      };

      const details = collaborators.flatMap(
        ({ info, deal_id, isHost, percentage }) => {
          if (isHost) return [];
          return [
            {
              deal_id,
              percentage,
              is_host: false,
              accepted: info.id === proposer
            }
          ];
        }
      );

      let newDetail = {
        accepted: false,
        deal: {
          data: {
            song_id: id,
            artist_id_from: host_id,
            artist_id_to: collaborator.id
          }
        },
        percentage: {
          recording: 0,
          publishing: 0,
          music_videos: 0
        }
      };

      return {
        ...split,
        split_details: { data: [...details, newDetail] }
      };
    },
    formatSplitDuplicated() {
      const { artist: collaborator } = this;
      const { id, collaborators, host_id } = this.song || this.addedSong;

      const proposer = collaborators.find(el => el.isMe).info.id;
      const split = {
        song_id: id,
        artist_id_proposer: proposer
      };

      const details = collaborators.flatMap(
        ({ info, deal_id, isHost, percentage }) => {
          if (isHost) return [];
          return [
            {
              deal_id,
              percentage,
              is_host: false,
              accepted: info.id === proposer
            }
          ];
        }
      );

      // let newDetail = {
      //   accepted: false,
      //   deal: {
      //     data: {
      //       song_id: id,
      //       artist_id_from: host_id,
      //       artist_id_to: collaborator.id
      //     }
      //   },
      //   percentage: {
      //     recording: 0,
      //     publishing: 0,
      //     music_videos: 0
      //   }
      // };

      return {
        ...split,
        split_details: { data: [...details] }
      };
    },
    getConnections() {
      const {
        artist: { id }
      } = this;
      const { host_id } = this.addedSong || this.song;
      return !id ? [] : [{ artist_id_from: host_id, artist_id_to: id }];
    },
    addCollaborator() {
      const split = this.formatSplit();
      const connections = this.getConnections();
      return this.$apollo.mutate({
        mutation: ADD_COLLABORATOR,
        variables: { split, connections },
        update: (store, { data: { insert_splits_one } }) => {
          try {
            const data = store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              }
            });

            const index = data.songs.findIndex(
              el => el.id === (this.song.id || this.addedSong.id)
            );
            data.songs[index] = {
              ...data.songs[index],
              splits: [insert_splits_one]
            };

            store.writeQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              },
              data
            });
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    addCollaborators() {
      const split = this.formatSplitDuplicated();
      const connections = this.getConnections();
      return this.$apollo.mutate({
        mutation: ADD_COLLABORATOR,
        variables: { split, connections },
        update: (store, { data: { insert_splits_one } }) => {
          try {
            const data = store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              }
            });

            const index = data.songs.findIndex(
              el => el.id === (this.song.id || this.addedSong.id)
            );
            data.songs[index] = {
              ...data.songs[index],
              splits: [insert_splits_one]
            };

            store.writeQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              },
              data
            });
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    addSong() {
      return this.$apollo.mutate({
        mutation: ADD_SONG,
        variables: {
          name: this.song.name,
          host_id: this.myArtist.id,
          spotify_id: this.song.spotify_id
        },
        update: (store, { data: { insert_songs_one } }) => {
          try {
            const data = store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              }
            });

            data.songs = [...data.songs, { ...insert_songs_one, splits: [] }];

            store.writeQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              },
              data
            });
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    addSongDuplicated() {
      return this.$apollo.mutate({
        mutation: ADD_SONG,
        variables: { name: this.song.name, host_id: this.myArtist.id },
        update: (store, { data: { insert_songs_one } }) => {
          try {
            const data = store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              }
            });

            data.songs = [...data.songs, { ...insert_songs_one, splits: [] }];

            store.writeQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              },
              data
            });
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    async add() {
      this.submitting = true;
      let song;
      if (!this.song.id) song = await this.addSong();
      this.addedSong = this.song.id
        ? this.song
        : await this.formatSongs([song.data.insert_songs_one])[0];
      if (this.artist.id) await this.addCollaborator();
      this.submitting = false;
      await this.trackContractHistory(
        this.addedSong.id,
        this.myArtist.id,
        "createdSong"
      );

      setTimeout(() => {
        window.location.href = `/songs?song=${this.song.id ||
          song.data.insert_songs_one.id}`;
      }, 500);
    },
    async addDuplicated() {
      this.song.id = null;
      this.submitting = true;
      let song;
      song = await this.addSong();
      this.addedSong = await this.formatSongs([song.data.insert_songs_one])[0];
      this.song.id = this.addedSong.id;
      await this.addCollaborators();

      await this.trackContractHistory(
        this.song.id,
        this.myArtist.id,
        "createdSong"
      );

      setTimeout(() => {
        window.location.href = `/songs?song=${this.song.id ||
          song.data.insert_songs_one.id}`;
      }, 500);
    },
    updateCompanyData() {
      const {
        song: { isHost },
        updateHostCompanyData,
        updateGuestCompanyData
      } = this;

      return isHost ? updateHostCompanyData() : updateGuestCompanyData();
    },
    updateHostCompanyData() {
      const {
        song: { id },
        companyQuestion: {
          answer: {
            value,
            recording_agreement,
            recording_agreement_third_party
          }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value)
        update = { is_host_company: false, rds: recording_agreement ? 4 : 5 };
      else {
        let rds;
        if (recording_agreement) rds = recording_agreement_third_party ? 2 : 1;
        else rds = recording_agreement_third_party ? 2 : 3;

        update = { is_host_company: true, host_company_id: value, rds };
      }

      this.$apollo.mutate({
        mutation: UPDATE_SONG,
        variables: { id, update },
        update: (store, { data: { update_songs_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          data.songs[song] = { ...data.songs[song], ...update_songs_by_pk };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.$emit("close");
        }
      });
    },
    updateGuestCompanyData() {
      const {
        song: { id, myDeal },
        companyQuestion: {
          answer: { value }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value) update = { is_guest_company: false };
      else update = { is_guest_company: true, guest_company_id: value };

      this.$apollo.mutate({
        mutation: UPDATE_DEAL,
        variables: { id: myDeal, update },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === myDeal
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            ...update_deals_by_pk
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.$emit("close");
        }
      });
    },
    async getArtistSongs(q = "") {
      try {
        const artist = this.$store.getters["account/getArtist"];
        console.log({ artist });
        const spotifyArtistId = artist.spotify_id;

        const SPOTIFY_SEARCH_SONG = gql`
          query spotify_search_tracks(
            $limit: Int!
            $offset: Int!
            $query: String!
            $artistId: String!
          ) {
            spotify_search_tracks(
              limit: $limit
              offset: $offset
              query: $query
              artistId: $artistId
            ) {
              id
              name
              preview_url
              album {
                id
                name
                images {
                  url
                  width
                  height
                }
              }
            }
          }
        `;
        console.log({ spotifyArtistId });
        const { data } = await this.$apollo.query({
          query: SPOTIFY_SEARCH_SONG,
          variables: {
            limit: 10,
            offset: this.spotifyOffset,
            query: q,
            artistId: spotifyArtistId
          }
        });

        this.songResults = data.spotify_search_tracks;
      } catch (error) {
        console.error("Error fetching artist songs:", error);
      }
    },
    async newFlowAdd() {
      if (this.newDeal) {
        if (this.unreleasedSong) {
          try {
            this.submitting = true;
            let song;
            if (!this.song.id) song = await this.addSong();
            this.addedSong = this.song.id
              ? this.song
              : await this.formatSongs([song.data.insert_songs_one])[0];
            if (this.artist.id) await this.addCollaborator();

            await this.trackContractHistory(
              this.addedSong.id,
              this.myArtist.id,
              "createdSong"
            );

            setTimeout(() => {
              window.location.href = `/songs?song=${this.song.id ||
                song.data.insert_songs_one.id}`;
            }, 500);
          } catch (error) {
            console.error("Error adding song:", error);
          } finally {
            this.submitting = false;
          }
        } else {
          try {
            this.song.name = this.spotifySelectedSong.name;
            this.song.spotify_id = this.spotifySelectedSong.id;
            this.submitting = true;
            let song;
            if (!this.song.id) song = await this.addSong();
            this.addedSong = this.song.id
              ? this.song
              : await this.formatSongs([song.data.insert_songs_one])[0];
            if (this.artist.id) await this.addCollaborator();
            await this.trackContractHistory(
              this.addedSong.id,
              this.myArtist.id,
              "createdSong"
            );

            setTimeout(() => {
              window.location.href = `/songs?song=${this.song.id ||
                song.data.insert_songs_one.id}`;
            }, 500);
          } catch (error) {
            console.error("Error adding song:", error);
          } finally {
            this.submitting = false;
          }
        }
      } else {
        if (this.unreleasedSong) {
          try {
            this.song.id = null;
            this.submitting = true;
            let song;
            song = await this.addSong();
            this.addedSong = await this.formatSongs([
              song.data.insert_songs_one
            ])[0];
            this.song.id = this.addedSong.id;
            await this.addCollaborators();

            await this.trackContractHistory(
              this.addedSong.id,
              this.myArtist.id,
              "createdSong"
            );

            setTimeout(() => {
              window.location.href = `/songs?song=${this.song.id ||
                song.data.insert_songs_one.id}`;
            }, 500);
          } catch (error) {
            console.error("Error adding song:", error);
          } finally {
            this.submitting = false;
          }
        } else {
          try {
            this.song.name = this.spotifySelectedSong.name;
            this.song.spotify_id = this.spotifySelectedSong.id;
            this.submitting = true;
            let song;
            song = await this.addSong();
            this.addedSong = await this.formatSongs([
              song.data.insert_songs_one
            ])[0];
            this.song.id = this.addedSong.id;
            await this.addCollaborators();

            await this.trackContractHistory(
              this.addedSong.id,
              this.myArtist.id,
              "createdSong"
            );

            setTimeout(() => {
              window.location.href = `/songs?song=${this.song.id ||
                song.data.insert_songs_one.id}`;
            }, 500);
          } catch (error) {
            console.error("Error adding song:", error);
          } finally {
            this.submitting = false;
          }
        }
      }
    }
  },
  apollo: {
    searchData: {
      query: SEARCH_SONGS,
      variables() {
        const {
          song: { name },
          offset,
          myArtist
        } = this;

        const where = {
          _and: [
            { name: { _ilike: `%${name || ""}%` } },
            { host_id: { _eq: myArtist.id } }
          ]
        };

        return { offset, limit: 10, where };
      },
      update({
        songs_aggregate: {
          aggregate: { count }
        },
        songs
      }) {
        const results = this.fetchingMore
          ? [...this.results, ...this.formatSongs(songs)]
          : this.formatSongs(songs);
        this.fetchingMore = false;
        return { results, total: count };
      },
      skip() {
        return this.searchDisabled;
      }
    },
    results: {
      query: GET_SONGS,
      variables() {
        return { artistId: this.$store.getters["account/getArtistId"] };
      },
      update({ artists, songs }) {
        this.previousSongs = songs;
        console.log({ songs });
        return {
          artists,
          songs: songs
        };
      }
    }
  },
  mounted() {
    this.getArtistSongs();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  height: fit-content;
  margin: 0 5rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: $white;
  }

  .inputs {
    min-width: 20rem;
    margin-bottom: 20px;

    .form-group {
      margin-bottom: 18px;

      input {
        @include details-light();
      }
    }
  }

  .switch-type {
    margin: 1rem 0;
    font-size: 0.9rem;
    color: $accent;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .search-title {
    font-size: 0.9rem;
    margin: 0.3rem;
    color: $text-additional;
    width: 80%;
  }

  .results-container {
    color: $white;
    width: 80%;
    max-height: 30vh;
    overflow-y: auto;
    padding-right: 0.5rem;
    margin-bottom: 1rem;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .results {
      .result {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        background-color: $gray-1;
        padding: 0.5rem;
        border-radius: 7.5px;

        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          margin-right: 0.5rem;
          border: 1px $accent solid;
        }

        .initials {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          font-family: sans-serif;
          font-size: 0.9rem;
          border-radius: 50%;
          background-color: $black;
          border: 1px solid $accent;
          color: $white;
          margin-right: 0.5rem;
        }

        .details {
          display: flex;
          flex-direction: column;

          span {
            font-size: 0.9rem;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #3d3d3d;
        }
      }
    }

    .searching,
    .fetch-more,
    .no-results {
      margin-bottom: 0.5rem;
      background-color: $gray-1;
      padding: 0.7rem;
      border-radius: 5px;
      font-size: 0.8rem;
      text-align: center;

      &:hover {
        cursor: pointer;
        background-color: #3d3d3d;
      }
    }
  }

  .buttons {
    button {
      margin: 0 0.5rem;
      min-width: 136px;
      height: 44px;
    }
  }
}

.song-tile {
  transition: min-height 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  min-height: 150px;
}

.song-tile:hover {
  min-height: 250px;
}
</style>
