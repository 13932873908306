<template>
  <AddArtist
    v-if="addingArtist"
    v-model="newArtist"
    @close="artist => artistAdded(artist)"
    :valid="newArtistValid"
    :userId="userId"
    :onboarded="true"
    :notSigned="true"
  />
  <transition v-else name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <h4>SWITCH ARTIST</h4>
              <div class="results-container">
                <div class="results">
                  <div
                    class="result"
                    :class="{
                      active: $store.getters['account/getArtistId'] === data.id
                    }"
                    v-for="(data, i) in artists"
                    :key="i"
                    @click="
                      $store.getters['account/getArtistId'] === data.id
                        ? 0
                        : switchArtist(data)
                    "
                  >
                    <img :src="data.avatar_url" v-if="data.avatar_url" />
                    <div class="initials" v-else>
                      {{ data.name | initials }}
                    </div>
                    <div class="details">
                      <span>{{ data.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="loading" v-if="$apollo.queries.myArtists.loading">
                  <span>Loading...</span>
                </div>
                <div class="add-artist" @click="addArtist">
                  <span>+ Add Artist</span>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import AddArtist from "@/components/modals/AddArtist.vue";

import GET_MY_ARTISTS from "@/api/queries/GET_MY_ARTISTS.gql";

export default {
  name: "SwitchArtist",
  components: {
    ModalCloseIcon,
    AddArtist
  },
  data() {
    return {
      loading: false,
      newArtist: {},
      addingArtist: false
    };
  },
  computed: {
    artists() {
      const { myArtists } = this;
      const current = this.$store.getters["account/getArtist"];
      return myArtists ? myArtists : [current];
    },
    isCompany() {
      return this.$store.getters["account/getUser"].company_users?.length > 0;
    },
    userId() {
      return this.$store.getters["account/getUserId"];
    },
    newArtistValid() {
      return (
        !!this.newArtist.firstName &&
        !!this.newArtist.lastName &&
        !!this.newArtist.email &&
        !!this.newArtist.talentName &&
        !!this.newArtist.PROAffiliation &&
        !!this.newArtist.songwriterIPINo &&
        !!this.newArtist.address
      );
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    switchArtist(artist) {
      this.$store.commit("account/updateArtist", artist);
      this.$emit("close");
    },
    addArtist() {
      if (this.isCompany) {
        this.$router.push({ name: "Settings", query: { type: "company" } });
        this.$emit("close");
      } else {
        this.addingArtist = true;
      }
    },
    artistAdded(artist) {
      if (!artist.id) return this.$emit("close");
      return this.switchArtist(artist);
    }
  },
  apollo: {
    myArtists: {
      query: GET_MY_ARTISTS,
      variables() {
        return { userId: this.$store.getters["account/getUserId"] };
      },
      update: ({ artists }) => artists
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 450px;
  max-height: 80%;
  height: fit-content;
  margin: 0 1rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h4 {
    color: $white;
    margin-bottom: 32px;
  }

  .results-container {
    color: $white;
    width: 70%;
    max-height: 30vh;
    overflow-y: auto;
    margin-bottom: 1rem;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .results {
      .result {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        background-color: $gray-1;
        padding: 0.5rem 1rem;
        border-radius: 7.5px;

        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          margin-right: 0.5rem;
          border: 1px $accent solid;
        }

        .initials {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          font-family: sans-serif;
          font-size: 0.9rem;
          border-radius: 50%;
          background-color: $accent;
          color: $white;
          margin-right: 0.5rem;
        }

        .details {
          display: flex;
          flex-direction: column;

          span {
            font-size: 0.9rem;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #3d3d3d;
        }
      }

      .active {
        cursor: pointer;
        background-color: #464646 !important;

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    .loading,
    .add-artist {
      margin-bottom: 0.5rem;
      background-color: $gray-1;
      padding: 0.7rem;
      border-radius: 5px;
      font-size: 0.8rem;
      text-align: center;

      &:hover {
        cursor: pointer;
        background-color: #3d3d3d;
      }

      span {
        height: 30px;
      }
    }
  }

  .buttons {
    margin-top: 1rem;
    display: flex;

    button {
      margin: 0 0.5rem;
      width: 136px;
      height: 44px;
    }
  }
}
</style>
