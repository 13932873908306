<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close', 'cancelled')" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <h4>ADD ARTIST OR PRODUCER</h4>
              <AddArtistForm
                v-model="value"
                :companyName="companyName"
                :notSigned="notSigned"
              />
              <div class="buttons">
                <button
                  class="primary outline dark"
                  @click="$emit('close', 'cancelled')"
                >
                  Cancel
                </button>
                <button
                  class="primary"
                  @click="addArtist"
                  :disabled="!valid || adding"
                >
                  {{ adding ? "Adding..." : "Add" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import AddArtistForm from "@/components/AddArtistForm.vue";

import GET_COMPANY from "@/api/queries/GET_COMPANY.gql";
import ADD_COMPANY_ARTIST from "@/api/mutations/ADD_COMPANY_ARTIST.gql";
import ADD_ARTIST_USER from "@/api/mutations/ADD_ARTIST_USER.gql";

export default {
  name: "AddArtist",
  components: {
    ModalCloseIcon,
    AddArtistForm
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    valid: {
      type: Boolean,
      required: true
    },
    notSigned: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: String,
      required: false
    },
    companyName: {
      type: String,
      required: false
    },
    onboarded: {
      type: Boolean,
      default() {
        return false;
      }
    },
    userId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      adding: false
    };
  },
  computed: {},
  methods: {
    addArtist() {
      if (!this.onboarded) return this.$emit("close", "success");

      this.adding = true;
      return this.notSigned
        ? this.addIndependentArtist()
        : this.addCompanyArtist();
    },
    addCompanyArtist() {
      this.$apollo.mutate({
        mutation: ADD_COMPANY_ARTIST,
        variables: {
          companyArtist: {
            company_id: this.companyId,
            recording_agreement: this.value.recording_agreement === "yes",
            recording_agreement_third_party:
              this.value.recording_agreement_third_party === "yes",
            artist: {
              data: {
                name: this.value.talentName,
                pro: this.value.PROAffiliation,
                ipi_songwriter: this.value.songwriterIPINo,
                ipi_publisher: this.value.publisherIPINo,
                publisher_name: this.value.publishingAdmin,
                address: this.value.address,
                artist_users: {
                  data: {
                    role: "artist",
                    access: "full",
                    user: {
                      data: {
                        first_name: this.value.firstName,
                        last_name: this.value.lastName,
                        email: this.value.email,
                        display_name: `${this.value.firstName} ${this.value.lastName}`,
                        status: "invited",
                        address: this.value.address
                      }
                    }
                  }
                }
              }
            }
          }
        },
        update: (store, { data: { insert_company_artists_one } }) => {
          const data = store.readQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            }
          });

          data.companies[0].company_artists = [
            ...data.companies[0].company_artists,
            insert_company_artists_one
          ];

          store.writeQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.adding = false;
          return this.$emit("close", "success");
        }
      });
    },
    addIndependentArtist() {
      this.$apollo.mutate({
        mutation: ADD_ARTIST_USER,
        variables: {
          artist_user: {
            role: "representative",
            access: "full",
            user_id: this.userId,
            artist: {
              data: {
                name: this.value.talentName,
                pro: this.value.PROAffiliation,
                ipi_songwriter: this.value.songwriterIPINo,
                ipi_publisher: this.value.publisherIPINo,
                publisher_name: this.value.publishingAdmin,
                address: this.value.address,
                artist_users: {
                  data: {
                    role: "artist",
                    access: "full",
                    user: {
                      data: {
                        first_name: this.value.firstName,
                        last_name: this.value.lastName,
                        email: this.value.email,
                        display_name: `${this.value.firstName} ${this.value.lastName}`,
                        status: "invited",
                        address: this.value.address
                      }
                    }
                  }
                }
              }
            }
          }
        },
        update: (store, { data: { insert_artist_users_one } }) => {
          if (!insert_artist_users_one.id) return this.$emit("close", null);

          const { company, artist } = insert_artist_users_one;

          this.adding = false;
          return this.$emit("close", {
            ...artist,
            company_artists: [],
            artist_users: {
              company
            }
          });
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  height: fit-content;
  margin: 0 5rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h4 {
    color: $white;
    margin-bottom: 32px;
  }
  h5 {
    color: $gray-6;
    font-weight: 300;
  }
  .buttons {
    button {
      margin: 0 0.5rem;
      width: 136px;
      height: 44px;
    }
  }
}
</style>
