<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="{ accent: accent, dark: dark, hovered: hovered }"
      d="M7.91668 15.8334C3.55167 15.8334 0 12.2817 0 7.91668C0 3.55167 3.55167 0 7.91668 0C12.2817 0 15.8334 3.55167 15.8334 7.91668C15.8334 12.2817 12.2817 15.8334 7.91668 15.8334ZM7.91668 1.25C4.24084 1.25 1.25 4.24084 1.25 7.91668C1.25 11.5925 4.24084 14.5834 7.91668 14.5834C11.5925 14.5834 14.5834 11.5925 14.5834 7.91668C14.5834 4.24084 11.5925 1.25 7.91668 1.25Z"
      fill="#2A2A2A"
    />
    <path
      :class="{ accent: accent, dark: dark, hovered: hovered }"
      d="M19.3766 19.9986C19.2166 19.9986 19.0566 19.9377 18.935 19.8152L12.8433 13.7235C12.5991 13.4794 12.5991 13.0835 12.8433 12.8394C13.0874 12.5952 13.4833 12.5952 13.7274 12.8394L19.8191 18.9311C20.0633 19.1752 20.0633 19.5711 19.8191 19.8152C19.6966 19.9377 19.5366 19.9986 19.3766 19.9986Z"
      fill="#2A2A2A"
    />
  </svg>
</template>

<script>
export default {
  name: "SearchIcon",
  props: {
    accent: Boolean,
    dark: Boolean
  },
  data() {
    return {
      hovered: false
    };
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
svg {
  cursor: pointer;
}
.accent {
  fill: $accent;
}

.dark {
  fill: white;
  &.hovered {
    fill: $accent;
  }
}
</style>
