<template>
  <div ref="container" class="w-full h-full relative" style="padding: 10px;">
    <div
      ref="threejsContainer"
      :style="{ width: 'calc(100% - 20px)', height: 'calc(100% - 20px)' }"
    ></div>
    <div
      class="absolute top-0 left-0 w-full h-full"
      style="background: linear-gradient(to bottom, #11000600, #11000600 80%, #110006);"
    ></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import throttle from "lodash/throttle";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
export default {
  name: "NvokoLogo3d",
  props: {
    width: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      targetRotationX: 0,
      targetRotationY: 0,
      currentRotationX: 0,
      currentRotationY: 0,
      windowHalfX: window.innerWidth / 2,
      windowHalfY: window.innerHeight / 2,
      rotationSpeed: 0.1,
      lastFlickerTime: 0,
      flickerDuration: 200,
      isFlickering: false,
      currentFlickerFrequency: 1.0,
      targetFlickerFrequency: 1.0,
      frequencyChangeSpeed: 0.01,
      flickerIntensity: 0,
      containerWidth: 0,
      containerHeight: 0,
      resizeObserver: null,
      colorShift: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.updateContainerSize();
      this.initThreeJS();

      this.frequencyInterval = setInterval(() => {
        this.targetFlickerFrequency = 0.7 + Math.random() * 1.3;
      }, 8000);
    });

    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.onResize(entry.contentRect);
      }
    });
    this.resizeObserver.observe(this.$refs.container);
  },
  methods: {
    onResize(contentRect) {
      this.containerWidth = contentRect.width;
      this.containerHeight = contentRect.height;
      if (this.camera && this.renderer) {
        this.camera.aspect = this.containerWidth / this.containerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.containerWidth, this.containerHeight);
      }
    },
    updateContainerSize() {
      if (this.$refs.container) {
        this.containerWidth = this.$refs.container.clientWidth;
        this.containerHeight = this.$refs.container.clientHeight;
        if (this.camera && this.renderer) {
          this.camera.aspect = this.containerWidth / this.containerHeight;
          this.camera.updateProjectionMatrix();
          this.renderer.setSize(this.containerWidth, this.containerHeight);
        }
      }
    },
    onWindowResize() {
      this.updateContainerSize();
    },
    onDocumentMouseMove(event) {
      this.mouseX =
        (event.clientX - this.containerWidth / 2) / (this.containerWidth / 2);
      this.mouseY =
        (event.clientY - this.containerHeight / 2) / (this.containerHeight / 2);
      this.targetRotationX = THREE.MathUtils.clamp(
        this.mouseY * 0.3,
        -Math.PI / 3.5,
        Math.PI / 3.5
      );
      this.targetRotationY = THREE.MathUtils.clamp(
        this.mouseX * 0.3,
        -Math.PI / 3.5,
        Math.PI / 3.5
      );
    },
    throttledMouseMove: throttle(function(event) {
      this.onDocumentMouseMove(event);
    }, 100),

    onDocumentScroll(event) {
      console.log({ event });
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollFraction = scrollTop / scrollHeight;

      this.targetRotationX = THREE.MathUtils.clamp(
        scrollFraction * Math.PI * 2,
        -Math.PI / 3.5,
        Math.PI / 3.5
      );
      this.targetRotationY = THREE.MathUtils.clamp(
        scrollFraction * Math.PI * 2,
        -Math.PI / 3.5,
        Math.PI / 3.5
      );
    },
    throttledScroll: throttle(function(event) {
      this.onDocumentScroll(event);
    }, 100),

    initThreeJS() {
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0x110006);

      const camera = new THREE.PerspectiveCamera(
        55,
        this.containerWidth / this.containerHeight,
        3,
        1000
      );
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      });

      const pointLight1 = new THREE.PointLight(0xffffff, 50.5);
      pointLight1.position.set(-3, 0.2, 0);
      scene.add(pointLight1);

      const pointLight2 = new THREE.PointLight(0xffffff, 50.5);
      pointLight2.position.set(1.5, 0.2, 0);
      scene.add(pointLight2);

      const pointLight3 = new THREE.PointLight(0xffffff, 50.5);
      pointLight3.position.set(3, 2.2, 0);
      scene.add(pointLight3);

      const pointLight4 = new THREE.PointLight(0xffffff, 50.5);
      pointLight4.position.set(-1.5, 2.2, 0);
      scene.add(pointLight4);

      renderer.setPixelRatio(2);
      renderer.gammaOutput = true;
      renderer.gammaFactor = 2.2;
      renderer.outputEncoding = THREE.sRGBEncoding;

      renderer.setSize(this.containerWidth, this.containerHeight);
      this.$refs.threejsContainer.appendChild(renderer.domElement);

      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");

      let model;
      const fbxLoader = new FBXLoader();
      fbxLoader.load(
        "/logo_light.fbx",
        object => {
          object.traverse(function(child) {
            if (child.isMesh) {
              if (Array.isArray(child.material)) {
                child.material = child.material.map(material => {
                  if (material.name === "red") {
                    return new THREE.MeshPhysicalMaterial({
                      color: new THREE.Color(
                        material.color.r,
                        material.color.g,
                        material.color.b
                      ),
                      emissive: new THREE.Color(
                        material.color.r,
                        material.color.g,
                        material.color.b
                      ),
                      emissiveIntensity: 0.1, // Intensity of the emissive light
                      roughness: 0, // Roughness of the material (0 = smooth, 1 = rough)
                      metalness: 1, // Metalness of the material (0 = non-metal, 1 = metal)
                      transmission: 0, // Transmission factor (1 = fully transmissive, 0 = opaque)
                      opacity: 0.1, // Opacity of the material (1 = fully opaque, 0 = fully transparent)
                      transparent: true, // Whether the material is transparent
                      ior: 1.5, // Index of refraction (affects how light bends through the material)
                      thickness: 0, // Thickness of the material (used for subsurface scattering)
                      clearcoat: 1, // Clearcoat layer on top of the material (0 = no clearcoat, 1 = full clearcoat)
                      clearcoatRoughness: 0, // Roughness of the clearcoat layer
                      reflectivity: 1, // Reflectivity of the material (0 = no reflection, 1 = full reflection)
                      refractionRatio: 0.98 // Refraction ratio for glass effect (affects how light bends through the material)
                    });
                  } else if (material.name === "white") {
                    return new THREE.MeshPhysicalMaterial({
                      color: new THREE.Color(0xffffff),
                      emissive: new THREE.Color(0xffffff),
                      emissiveIntensity: 0.1,
                      roughness: 0,
                      metalness: 1,
                      transmission: 0,
                      opacity: 0.2,
                      transparent: true,
                      ior: 1,
                      thickness: 0.1,
                      clearcoat: 0,
                      clearcoatRoughness: 0
                    });
                  } else if (material.name === "red.001") {
                    return new THREE.MeshPhysicalMaterial({
                      color: new THREE.Color(
                        material.color.r,
                        material.color.g,
                        material.color.b
                      ),
                      emissive: new THREE.Color(
                        material.color.r,
                        material.color.g,
                        material.color.b
                      ),
                      emissiveIntensity: 12,
                      roughness: 0,
                      metalness: 0,
                      transmission: 1.0,
                      opacity: 1
                    });
                  } else if (material.name === "white.001") {
                    return new THREE.MeshPhysicalMaterial({
                      color: new THREE.Color(0xffffff),
                      emissive: new THREE.Color(0xffffff),
                      emissiveIntensity: 12,
                      roughness: 0,
                      metalness: 0,
                      transmission: 1.0,
                      opacity: 1
                    });
                  }
                  return material;
                });
              }
            }
          });

          const modelScale =
            1 - (1 - (this.width / this.containerWidth) * 0.25);
          object.scale.set(modelScale, modelScale, modelScale);
          const modelXPosition = THREE.MathUtils.clamp(
            this.containerWidth / 400,
            0,
            1.5
          );
          object.position.set(modelXPosition * -1, 0.2, 0);
          scene.add(object);
          model = object;

          camera.position.z = 5;

          const animate = () => {
            requestAnimationFrame(animate);
            composer.render();
          };
          animate();
        },
        undefined,
        error => {
          console.error("An error happened while loading the model:", error);
        }
      );
      const renderScene = new RenderPass(scene, camera);
      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(this.containerWidth, this.containerHeight),
        1.5,
        0.1,
        0.5
      );
      bloomPass.threshold = 0;

      const composer = new EffectComposer(renderer);
      composer.addPass(renderScene);
      composer.addPass(bloomPass);

      const page = document.querySelector(".page-container");

      // Add particles
      const particlesCount = 3000;
      const particlesGeometry = new THREE.BufferGeometry();
      const particlesMaterial = new THREE.PointsMaterial({
        color: 0xff035a,
        size: 0.004,
        opacity: 1,
        transparent: true,
        blending: THREE.AdditiveBlending,
        emissive: 0xffffff,
        emissiveIntensity: 1000,
        depthWrite: false,
        depthTest: false
      });

      const positions = new Float32Array(particlesCount * 3);
      for (let i = 0; i < particlesCount; i++) {
        positions[i * 3] = (Math.random() - 0.5) * 10;
        positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
        positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
      }
      particlesGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );

      const particles = new THREE.Points(particlesGeometry, particlesMaterial);

      scene.add(particles);

      const animate = () => {
        requestAnimationFrame(animate);

        if (this.currentFlickerFrequency < this.targetFlickerFrequency) {
          this.currentFlickerFrequency += this.frequencyChangeSpeed;
          if (this.currentFlickerFrequency > this.targetFlickerFrequency) {
            this.currentFlickerFrequency = this.targetFlickerFrequency;
          }
        } else if (this.currentFlickerFrequency > this.targetFlickerFrequency) {
          this.currentFlickerFrequency -= this.frequencyChangeSpeed;
          if (this.currentFlickerFrequency < this.targetFlickerFrequency) {
            this.currentFlickerFrequency = this.targetFlickerFrequency;
          }
        }

        this.flickerIntensity =
          Math.sin(
            ((Date.now() / 1000) * Math.PI * 2) / this.currentFlickerFrequency
          ) *
            0.5 +
          0.5;

        bloomPass.strength =
          (0.7 + Math.abs(this.mouseX) * 0.2) *
          (0.2 + this.flickerIntensity * 0.05);
        bloomPass.radius =
          (0.15 + Math.abs(this.mouseY) * 0.1) *
          (0.5 + this.flickerIntensity * 0.025);

        if (model) {
          const scrollTop = page.scrollTop;
          const scrollHeight = page.scrollHeight;
          const scrollFraction = scrollTop / scrollHeight;

          particles.rotation.y += 0.0005 + 0.005 * scrollFraction;
          particles.rotation.x += 0.0008 + 0.04 * scrollFraction;
          particles.rotation.z += 0.0012 + 0.01 * scrollFraction;

          particles.material.size = 0.004 + 0.01 * scrollFraction;

          // Dynamic target rotation based on scroll
          this.targetRotationX = THREE.MathUtils.clamp(
            -scrollFraction * Math.PI * 2,
            -Math.PI / 3.5,
            Math.PI / 3.5
          );
          this.targetRotationY = THREE.MathUtils.clamp(
            scrollFraction * Math.PI * 2,
            -Math.PI / 13.5,
            Math.PI / 13.5
          );

          // Smooth transition to target rotation
          this.currentRotationX = THREE.MathUtils.lerp(
            this.currentRotationX,
            this.targetRotationX,
            this.rotationSpeed
          );
          this.currentRotationY = THREE.MathUtils.lerp(
            this.currentRotationY,
            this.targetRotationY,
            this.rotationSpeed
          );

          // Apply rotation to the model
          model.rotation.x = this.currentRotationX;
          model.rotation.y = this.currentRotationY;
        }

        composer.render();
      };

      animate();
    }
  }
};
</script>

<style scoped></style>
