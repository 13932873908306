<template>
  <ForgotPassword v-if="forgotPassword" @close="forgotPassword = false" />
  <transition v-else name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="logo">
                <img src="@/assets/logo-n.svg" alt="Nvoko Logo" />
              </div>
              <h2>Sign In</h2>
              <form>
                <div class="inputs">
                  <div class="form-group">
                    <input
                      v-model="email"
                      id="email"
                      type="email"
                      class="text-input dark"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="password"
                      id="password"
                      type="password"
                      class="text-input dark"
                      placeholder="Type the password"
                    />
                  </div>
                </div>
                <button
                  @click="onLoginClick()"
                  class="primary"
                  :disabled="!enableLoginButton || isLoading"
                >
                  {{ loginButtonText }}
                </button>
              </form>
              <span class="forgot-password" @click="forgotPassword = true">
                Forgot Password?
              </span>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import ForgotPassword from "@/components/modals/ForgotPassword.vue";

import LOGIN from "@/api/mutations/LOGIN.gql";
import GET_DEFAULT_ARTIST from "@/api/queries/GET_DEFAULT_ARTIST.gql";

export default {
  name: "LoginModal",
  components: {
    ModalCloseIcon,
    ForgotPassword
  },
  data() {
    return {
      email: null,
      password: null,
      isLoading: false,
      forgotPassword: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["account/getUser"];
    },
    enableLoginButton() {
      return this.email && this.password;
    },
    loginButtonText() {
      return this.isLoading ? "Loading..." : "Log In";
    }
  },
  methods: {
    async login() {
      const {
        data: { login }
      } = await this.$apollo.mutate({
        mutation: LOGIN,
        variables: {
          email: this.email.trim(),
          password: this.password.trim()
        }
      });

      this.$token = login.token;
      this.$store.commit("account/authSuccess", login);
    },
    async onLoginClick() {
      try {
        this.isLoading = true;
        await this.login();
        const {
          data: { artists }
        } = await this.$apollo.query({
          query: GET_DEFAULT_ARTIST,
          variables: {
            userId: this.user.id
          }
        });
        this.$store.commit("account/updateArtist", artists[0]);
        if (this.user.status === "unverified")
          this.$router.push({ name: "SignupVerification" });
        else if (!this.user.onboarding_complete)
          this.$router.push({ name: "Onboarding" });
        else this.$router.push({ name: "Songs" });
      } catch (e) {
        alert(e.message || e);
        this.isLoading = false;
      }
    }
  },
  apollo: {
    // artist: {
    //   query: GET_DEFAULT_ARTIST,
    //   variables() {
    //     return { userId: this.user.id };
    //   },
    //   update({ artists }) {
    //     this.$store.commit("account/updateArtist", artists[0]);
    //     if (this.user.status === "unverified")
    //       this.$router.push({ name: "SignupVerification" });
    //     else if (!this.user.onboarding_complete)
    //       this.$router.push({ name: "Onboarding" });
    //     else this.$router.push({ name: "Songs" });
    //   },
    //   skip() {
    //     return !(this.user || {}).id;
    //   }
    // }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../styles/_mixins.scss";

.modal-body {
  padding: 34px 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 32px;
  }

  h2 {
    color: $white;
  }

  form {
    margin-top: 20px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .inputs {
      width: 100%;

      .form-group {
        margin-bottom: 24px;

        input {
          @include details-light();
          color: $white;
        }
      }
    }

    button {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.22px;
      padding: 10px 30px 12px;
    }
  }

  .forgot-password {
    color: $accent;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
