import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import accountModule from "./modules/account.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account: accountModule
  },
  plugins: [createPersistedState()]
});
