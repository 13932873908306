<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="logo">
                <img src="@/assets/logo-n.svg" alt="Nvoko Logo" />
              </div>
              <div class="form-container" v-if="!success">
                <h3>Enter your Email:</h3>
                <form>
                  <div class="inputs">
                    <div class="form-group">
                      <input
                        v-model="email"
                        id="email"
                        type="email"
                        class="text-input dark"
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                  <button
                    @click="submit()"
                    class="primary"
                    :disabled="!email || submitting"
                  >
                    {{ submitting ? "Loading..." : "Submit" }}
                  </button>
                </form>
              </div>
              <div class="success" v-else>
                <div class="check">
                  <img src="../../assets/icons/Check.svg" />
                </div>
                <span>A password reset link was sent to your email</span>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import RESET_PASSWORD_REQUEST from "@/api/mutations/RESET_PASSWORD_REQUEST.gql";

export default {
  name: "ForgotPassword",
  components: {
    ModalCloseIcon
  },
  data() {
    return {
      email: null,
      submitting: false,
      success: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["account/getUser"];
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        const {
          data: { reset_password_request }
        } = await this.$apollo.mutate({
          mutation: RESET_PASSWORD_REQUEST,
          variables: { email: this.email.trim() }
        });
        this.success = reset_password_request.success;
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../styles/_mixins.scss";
.modal-body {
  padding: 34px 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    margin-bottom: 32px;
  }
  .form-container {
    width: 100%;
    h3 {
      color: $white;
      text-align: center;
    }
    form {
      margin-top: 40px;
      margin-bottom: 8px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .inputs {
        width: 100%;
        .form-group {
          margin-bottom: 30px;
          input {
            @include details-light();
            color: $white;
          }
        }
      }
      button {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.22px;
        padding: 10px 30px 12px;
      }
    }
  }
  .success {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .check {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: $accent;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
      }
    }
    span {
      margin-top: 2rem;
      font-size: 1.3rem;
      color: $white;
      text-align: center;
    }
  }
}
</style>
