<template>
  <div class="modal-header">
    <slot name="header">
      <div class="logo">
        <img v-if="!hideLogo" src="../../assets/logo.png" alt="Nvoko Logo" />
      </div>
      <div @click="$emit('close')" class="icon">
        <ModalCloseIcon :dark="true" />
      </div>
      <div v-if="progress" class="progressbar">
        <div class="line" :style="{ width: progress + '%' }"></div>
      </div>
    </slot>
  </div>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
export default {
  name: "SignupHeader",
  props: {
    hideLogo: Boolean,
    progress: Number
  },
  components: {
    ModalCloseIcon
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../styles/_mixins.scss";
.modal-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 32px;
  .logo {
    width: 55px;
  }
  .progressbar {
    height: 10px;
    width: 100%;
    margin: 51px 148px 8px;
    background: $text;
    border-radius: 10px;
    .line {
      height: 10px;
      // width: 50%;
      background: $accent;
      border-radius: 10px;
    }
  }
}
</style>
