<template>
  <svg
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="{ accent: accent, dark: dark, hovered: hovered }"
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#3B3B3B"
    />
  </svg>
</template>

<script>
export default {
  name: "ModalCloseIcon",
  props: {
    accent: Boolean,
    dark: Boolean
  },
  data() {
    return {
      hovered: false
    };
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
svg {
  cursor: pointer;
}
.accent {
  fill: $accent;
}

.hovered {
  fill: $accent;
}

.dark {
  fill: white;
  &.hovered {
    fill: $accent;
  }
}
</style>
