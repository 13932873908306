<template>
  <span
    class="relative flex flex-wrap group transform-gpu"
    :class="[
      `words-${id}`,
      lineHeight,
      oneWordPerLine ? 'flex-col' : null,
      center ? 'justify-center items-center' : null
    ]"
  >
    <span
      class="relative z-10 flex opacity-0 flex-nowrap transform-gpu"
      :class="[`word-${id}`, letterSpacing, shadow ? 'drop-shadow' : null]"
      v-for="(word, i) in words"
      :key="`word-${i}-${id}`"
    >
      <template v-if="!wordsOnly">
        <span
          class="letter transform-gpu"
          :class="`letter-${id}`"
          v-for="(letter, i) in word"
          :key="`letter-${i}-${id}`"
        >
          {{ letter }}
        </span>
      </template>
      <template v-else>
        {{ word }}
      </template>
      <span v-if="i < words.length - 1">&nbsp;</span>
    </span>
    <span
      v-if="chevron"
      class="flex items-center justify-center mx-2 transition-all duration-300 opacity-0 transform-gpu chevron"
      :class="`chevron-${id}`"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5685 9.36863C12.4044 9.53267 12.1819 9.62482 11.9499 9.62482C11.7179 9.62482 11.4954 9.53267 11.3313 9.36863L7.00003 5.03738L2.66878 9.36863C2.50376 9.52802 2.28273 9.61621 2.05331 9.61422C1.82388 9.61222 1.60442 9.5202 1.44219 9.35797C1.27996 9.19574 1.18794 8.97628 1.18595 8.74685C1.18395 8.51743 1.27215 8.29641 1.43153 8.13138L6.3814 3.1815C6.54549 3.01747 6.76801 2.92532 7.00003 2.92532C7.23205 2.92532 7.45456 3.01747 7.61865 3.1815L12.5685 8.13138C12.7326 8.29547 12.8247 8.51799 12.8247 8.75C12.8247 8.98202 12.7326 9.20454 12.5685 9.36863Z"
          fill="currentColor"
        />
      </svg>
    </span>
    <span
      class="absolute bottom-0 z-0 w-full h-1 -translate-x-1/2 transform-gpu left-1/2"
      v-if="reactiveUndeline"
      :class="`reactive-underline-${id}`"
    >
      <span
        class="absolute bottom-0 transform-gpu left-0 w-full transition-all duration-300 ease-ilmam h-[1px] bg-dark-body group-hover:h-3 group-hover:bg-[#333] rounded-sm"
      ></span>
    </span>
  </span>
</template>

<script>
import { gsap } from "gsap";
import _ from "lodash";

export default {
  props: {
    textToAnim: String,
    offset: {
      type: Number,
      default: 20
    },
    wordsOnly: {
      type: Boolean,
      default: false
    },
    wordsStagger: {
      type: Number,
      default: 0.1
    },
    lettersStagger: {
      type: Number,
      default: 0.05
    },
    groupId: String,
    splitDelimiter: {
      type: String,
      default: " "
    },
    delay: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 1.5
    },
    rotation: {
      type: Number,
      default: 6
    },
    scaleTo: {
      type: Number,
      default: 1
    },
    scaleFrom: {
      type: Number,
      default: 1
    },
    reactiveUndeline: Boolean,
    chevron: Boolean,
    oneWordPerLine: Boolean,
    center: Boolean,
    lineHeight: {
      type: String,
      default: "gap-y-0"
    },
    letterSpacing: {
      type: String,
      default: "gap-x-2.5"
    },
    shadow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: this.groupId || this.randomString(10),
      words: []
    };
  },
  created() {
    this.generateId();
  },
  mounted() {
    this.$nextTick(() => this.createWords());
  },

  methods: {
    randomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    staggering() {
      const localizationFactor = -1;
      const wordSelector = `.word-${this.id}`;
      const letterSelector = `.letter-${this.id}`;
      const underlineSelector = `.reactive-underline-${this.id}`;
      const chevronSelector = `.chevron-${this.id}`;

      requestAnimationFrame(() => {
        gsap.from(wordSelector, {
          y: this.offset,
          rotation: this.rotation * localizationFactor,
          opacity: 0,
          scaleX: this.scaleFrom,
          scaleY: this.scaleFrom,
          duration: this.duration,
          delay: this.delay,
          color: "#ff035a",
          filter: "blur(4px)",
          stagger: this.wordsStagger,
          ease: "Expo.easeOut",
          boxShadow: "0px 0px 10px rgba(255, 3, 90, 0.5)",
          scrollTrigger: {
            trigger: `.words-${this.id}`,
            pin: false,
            start: "top bottom",
            scrub: false,
            once: true
          }
        });

        gsap.to(wordSelector, {
          y: 0,
          opacity: 1,
          delay: this.delay,
          duration: this.duration,
          rotation: 0,
          scaleX: this.scaleTo,
          scaleY: this.scaleTo,
          color: "#ffffff",
          filter: "blur(0px)",
          stagger: this.wordsStagger,
          ease: "Expo.easeOut",
          boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
          scrollTrigger: {
            trigger: `.words-${this.id}`,
            pin: false,
            start: "top bottom",
            scrub: false,
            once: true
          }
        });

        // gsap.from(wordSelector, {
        //   y: this.offset,
        //   rotation: this.rotation * localizationFactor,
        //   opacity: 0,
        //   scaleX: this.scaleFrom,
        //   scaleY: this.scaleFrom,
        //   duration: this.duration,
        //   color: "#ff035a",
        //   filter: "blur(4px)",
        //   stagger: this.wordsStagger,
        //   ease: "Expo.easeOut",
        //   scrollTrigger: {
        //     trigger: `.words-${this.id}`,
        //     pin: false,
        //     start: "top bottom",
        //     scrub: false,
        //     once: true,
        //   },
        // });

        // gsap.to(wordSelector, {
        //   y: 0,
        //   opacity: 1,
        //   delay: this.delay,
        //   duration: this.duration,
        //   rotation: 0,
        //   scaleX: this.scaleTo,
        //   scaleY: this.scaleTo,
        //   color: "#ffffff",
        //   filter: "blur(0px)",
        //   stagger: this.wordsStagger,
        //   ease: "Expo.easeOut",
        //   scrollTrigger: {
        //     trigger: `.words-${this.id}`,
        //     pin: false,
        //     start: "top bottom",
        //     scrub: false,
        //     once: true,
        //   },
        // });

        if (!this.wordsOnly) {
          gsap.from(letterSelector, {
            y: this.offset,
            opacity: 0,
            delay: this.delay,
            duration: this.duration,
            stagger: this.lettersStagger,
            ease: "Expo.easeOut",
            scrollTrigger: {
              trigger: `.words-${this.id}`,
              pin: false,
              start: "top bottom",
              scrub: false,
              once: true
            }
          });

          gsap.to(letterSelector, {
            y: 0,
            opacity: 1,
            delay: this.delay,
            duration: this.duration,
            stagger: this.lettersStagger,
            ease: "Expo.easeOut",
            scrollTrigger: {
              trigger: `.words-${this.id}`,
              pin: false,
              start: "top bottom",
              scrub: false,
              once: true
            }
          });
        }

        if (this.reactiveUndeline) {
          gsap.from(underlineSelector, {
            y: this.offset,
            opacity: 0,
            delay: this.calculateUnderlineDelay(),
            duration: this.duration,
            stagger: this.lettersStagger,
            ease: "power4.inOut",
            scrollTrigger: {
              trigger: `.words-${this.id}`,
              pin: false,
              start: "top bottom",
              scrub: false,
              once: true
            }
          });

          gsap.to(underlineSelector, {
            y: 0,
            opacity: 1,
            delay: this.calculateUnderlineDelay(),
            duration: this.duration,
            stagger: this.lettersStagger,
            ease: "power4.inOut",
            scrollTrigger: {
              trigger: `.words-${this.id}`,
              pin: false,
              start: "top bottom",
              scrub: false,
              once: true
            }
          });
        }

        if (this.chevron) {
          gsap.from(chevronSelector, {
            y: this.offset,
            opacity: 0,
            delay: this.calculateUnderlineDelay(),
            duration: 0.7,
            ease: "Expo.easeOut",
            scrollTrigger: {
              trigger: `.words-${this.id}`,
              pin: false,
              start: "top bottom",
              scrub: false,
              once: true
            }
          });

          gsap.to(chevronSelector, {
            y: 0,
            opacity: 1,
            delay: this.calculateUnderlineDelay(),
            duration: 0.7,
            ease: "Expo.easeOut",
            scrollTrigger: {
              trigger: `.words-${this.id}`,
              pin: false,
              start: "top bottom",
              scrub: false,
              once: true
            }
          });
        }
      });
    },
    calculateUnderlineDelay() {
      return this.delay + this.words.length * this.wordsStagger + 0.1;
    },
    generateId() {
      this.id = this.groupId || this.randomString(10);
    },
    createWords() {
      if (!this.textToAnim || typeof this.textToAnim !== "string") return;
      this.generateId();
      if (this.splitDelimiter !== "null") {
        let words = this.textToAnim.trim().split(this.splitDelimiter);
        if (!this.wordsOnly) {
          words = words.map(word => [...word, this.splitDelimiter]);
        } else {
          words = words.map(word => word + this.splitDelimiter);
        }
        this.words = words;
      } else {
        this.words = [this.textToAnim.trim()];
      }
    }
  },
  watch: {
    words() {
      this.$nextTick(() => this.staggering());
    },
    textToAnim() {
      this.createWords();
    }
  }
};
</script>
