var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('Dropdown',{staticClass:"w-full",scopedSlots:_vm._u([{key:"button",fn:function(ref){
var toggleDropdown = ref.toggleDropdown;
var isDropdownOpen = ref.isDropdownOpen;
return [_c('button',{staticClass:"flex justify-between artist",staticStyle:{"width":"280px"},on:{"click":toggleDropdown}},[_c('div',{staticClass:"flex items-center justify-start flex-1"},[(_vm.artist.avatar_url)?_c('img',{staticClass:"w-full h-full object-cover",attrs:{"src":_vm.artist.avatar_url,"alt":""}}):_c('div',{staticClass:"initials"},[_vm._v(_vm._s(_vm._f("initials")(_vm.artist.name)))]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.artist.name))]),(_vm.isPro)?_c('span',{staticClass:"pro"},[_vm._v("PRO")]):_vm._e()]),_c('span',{staticClass:"ml-auto"},[_c('svg',{staticClass:"icon icon-tabler icon-tabler-chevron-down",style:(isDropdownOpen ? 'transform: rotate(180deg)' : ''),attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"#ffffff","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M6 9l6 6l6 -6"}})])])])]}},{key:"items",fn:function(ref){
var toggleDropdown = ref.toggleDropdown;
return [_c('p',{staticClass:"px-2 mb-2 text-gray-400"},[_vm._v("User")]),_c('router-link',{staticStyle:{"margin-bottom":"3px"},attrs:{"to":{ name: 'Settings', query: { type: 'user' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{staticClass:"menu-item",on:{"click":function () {
                navigate();
                toggleDropdown();
              }}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Dashboard.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" User Settings ")])])])]}}],null,true)}),_c('hr',{staticClass:"my-2 border-gray-700"}),_c('p',{staticClass:"px-2 mb-2 text-gray-400"},[_vm._v("Artist")]),_c('router-link',{staticStyle:{"margin-bottom":"3px"},attrs:{"to":{ name: 'Settings', query: { type: 'artist' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
return [_c('div',{staticClass:"menu-item",on:{"click":function () {
                navigate();
                toggleDropdown();
              }}},[_c('div',{staticClass:"icon"},[_c('svg',{staticClass:"icon icon-tabler icon-tabler-microphone-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"#ffffff90","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M15 12.9a5 5 0 1 0 -3.902 -3.9"}}),_c('path',{attrs:{"d":"M15 12.9l-3.902 -3.899l-7.513 8.584a2 2 0 1 0 2.827 2.83l8.588 -7.515z"}})])]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Artist Settings ")])])])]}}],null,true)}),_c('div',{staticClass:"menu-item",staticStyle:{"margin-bottom":"3px"},on:{"click":function () {
              _vm.showModal = 'switch-artist';
              toggleDropdown();
            }}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Switch.svg")}})]),_c('div',{staticClass:"name"},[_vm._v(" Switch Artist ")])]),_c('hr',{staticClass:"my-2 border-gray-700"}),_c('div',{staticClass:"menu-item",staticStyle:{"margin-bottom":"0px"},on:{"click":function () {
              _vm.logout();
              toggleDropdown();
            }}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Logout.svg")}})]),_c('div',{staticClass:"name"},[_vm._v(" Log out ")])])]}}])}),_c('div',{staticClass:"menu"},[(_vm.isSettings)?[_c('div',{staticClass:"menu-item back",on:{"click":function($event){_vm.isSettings = false}}},[_vm._m(1),_c('div',{staticClass:"name"},[_vm._v(" Main Menu ")])]),_c('div',{staticClass:"section-title"},[_vm._v("Artist")]),_c('router-link',{attrs:{"to":{ name: 'ArtistProfile' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Dashboard.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Settings ")])])])]}}],null,false,1550610700)}),_c('router-link',{attrs:{"to":{ name: 'TeamManagement' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Feedback.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" My Team ")])])])]}}],null,false,747782413)}),_c('div',{staticClass:"section-title"},[_vm._v("User")]),_c('router-link',{attrs:{"to":{ name: 'UserProfile' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Dashboard.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Settings ")])])])]}}],null,false,1550610700)}),_c('router-link',{attrs:{"to":{ name: 'Subscription' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Feedback.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Subscription ")])])])]}}],null,false,1103169799)}),_c('router-link',{attrs:{"to":{ name: 'Payouts' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Feedback.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Payouts ")])])])]}}],null,false,2539991409)})]:[_c('div',{staticClass:"menu-item create-deal",on:{"click":function($event){_vm.showModal = 'add-song'}}},[_vm._m(2),_c('div',{staticClass:"name"},[_vm._v(" Create Deal ")])]),_c('router-link',{attrs:{"to":{ name: 'Songs' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Catalog.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Songs ")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'Catalog' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.12552 14.251C3.19673 14.251 0 11.0543 0 7.12552C0 3.19673 3.19673 0 7.12552 0C11.0543 0 14.251 3.19673 14.251 7.12552C14.251 11.0543 11.0543 14.251 7.12552 14.251ZM7.12552 1.12508C3.81703 1.12508 1.12508 3.81703 1.12508 7.12552C1.12508 10.434 3.81703 13.126 7.12552 13.126C10.434 13.126 13.126 10.434 13.126 7.12552C13.126 3.81703 10.434 1.12508 7.12552 1.12508Z","fill":isActive ? '#FF035A' : '#ffffff'}}),_c('path',{attrs:{"d":"M17.4398 17.9992C17.2958 17.9992 17.1518 17.9444 17.0422 17.8342L11.5594 12.3513C11.3396 12.1315 11.3396 11.7752 11.5594 11.5554C11.7791 11.3357 12.1354 11.3357 12.3552 11.5554L17.8381 17.0384C18.0578 17.2581 18.0578 17.6144 17.8381 17.8342C17.7278 17.9444 17.5838 17.9992 17.4398 17.9992Z","fill":isActive ? '#FF035A' : '#ffffff'}})])]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Catalog ")])])])]}}])}),(false)?_c('router-link',{attrs:{"to":{ name: 'Wallet' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Wallet.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Wallet ")])])])]}}],null,false,4187810253)}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'Contacts' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/User.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Collaborators ")])])])]}}])})]],2),_c('div',{staticClass:"w-full px-5 mt-auto logout"}),_vm._m(3)],1),(_vm.showModal === 'switch-artist')?_c('SwitchArtist',{on:{"close":function($event){_vm.showModal = ''}}}):_vm._e(),(_vm.showModal === 'add-song')?_c('AddSong',{attrs:{"my-artist":_vm.artist,"add-new-required":""},on:{"close":function($event){_vm.showModal = ''}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon flip"},[_c('img',{attrs:{"src":require("../assets/icons/Arrow-right-alt.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/CreateDeal.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-wrap w-full bottom"},[_c('img',{staticClass:"n-logo",attrs:{"src":require("../assets/logo.png")}}),_vm._v(" © 2024. "),_c('a',{staticClass:"link",attrs:{"href":"/terms-of-use"}},[_vm._v("Terms.")]),_c('a',{staticClass:"link",attrs:{"href":"https://app.termly.io/document/privacy-policy/edbf75fb-d7c1-4c85-81b7-8f9a585e29d5"}},[_vm._v("Privacy.")])])}]

export { render, staticRenderFns }