<template>
  <div :class="{ 'no-scroll': withSidebar }">
    <div id="app" :class="{ 'with-sidebar': withSidebar }">
      <Sidebar v-if="withSidebar" class="main-sidebar" />
      <router-view class="page-container" />
    </div>
    <tooltip />
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";

import USER_DETAILS_SUBSCRIPTION from "@/api/subscriptions/USER_DETAILS_SUBSCRIPTION.gql";

export default {
  data() {
    return {
      showModal: false
    };
  },
  name: "App",
  components: { Sidebar },
  watch: {
    withSidebar() {
      this.resetSocketConnection();
    }
  },
  computed: {
    withSidebar() {
      const routes = [
        "/",
        "/home",
        "/signup",
        "/signup/verification",
        "/onboarding",
        "/reset-password",
        "/waitlisted"
      ];
      return !routes.includes(this.$route.path);
    }
  },
  apollo: {
    $subscribe: {
      details: {
        query: USER_DETAILS_SUBSCRIPTION,
        variables() {
          return { id: this.$store.getters["account/getUserId"] };
        },
        result(data) {
          const {
            charges_enabled,
            payouts_enabled,
            subscriptions,
            company_users
          } = data.data.users_by_pk;

          this.$store.commit("account/updateUser", {
            charges_enabled,
            payouts_enabled,
            subscription: subscriptions[0],
            company_users
          });
        },
        skip() {
          return !this.withSidebar;
        }
      }
    }
  }
};
</script>

<style lang="scss">
body {
  background: #54001d;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

#app {
  font-family: sofia-pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  display: flex;

  .main-sidebar {
    min-width: 279px;
    height: 100%;
  }

  .page-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;

    .content {
      padding-bottom: 32px;
    }
  }
}

.with-sidebar {
  background-color: $white;
  border-radius: 20px;
  border: 2px solid #fff;
  box-shadow: 0px 10px 50px rgb(0 0 0);
  margin: 25px;
  height: calc(100vh - 50px);
  overflow: hidden;
}
</style>
