<template>
  <div class="relative px-6 py-24 isolate sm:py-32 lg:px-8">
    <div
      class="absolute inset-x-0 overflow-hidden -top-3 -z-10 transform-gpu px-36 blur-3xl"
      aria-hidden="true"
    >
      <div
        class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
      />
    </div>
    <div class="max-w-2xl mx-auto text-center lg:max-w-4xl">
      <h2 class="text-base font-semibold leading-7 text-nvoko">Pricing</h2>
      <p class="mt-0 text-5xl font-bold tracking-tight text-white sm:text-5xl">
        Secure your rights
      </p>
    </div>
    <p
      class="max-w-2xl mx-auto mt-3 text-xl leading-8 text-center text-gray-200"
    >
      Get your contracts covering <span class="text-nvoko">buy-out</span>,
      <span class="text-nvoko">record royalties</span>, and
      <span class="text-nvoko">songwriter splits</span> — all in
      <span class="text-nvoko">one place</span>.
    </p>
    <div
      class="grid items-center max-w-lg grid-cols-1 mx-auto mt-16 gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2"
    >
      <div
        v-for="(tier, tierIdx) in tiers"
        :key="tier.id"
        :class="getTierClasses(tier, tierIdx)"
      >
        <h3 :id="tier.id" :class="getTierHeaderClasses(tier)">
          {{ tier.name }}
        </h3>
        <p class="flex items-baseline mt-4 gap-x-2">
          <span :class="getTierPriceClasses(tier)">{{
            tier.priceMonthly
          }}</span>
          <span :class="getTierPriceSuffixClasses(tier)">/month</span>
        </p>
        <p :class="getTierDescriptionClasses(tier)">
          {{ tier.description }}
        </p>
        <ul role="list" :class="getTierListClasses(tier)">
          <li
            v-for="feature in tier.features"
            :key="feature"
            class="flex gap-x-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :class="getCheckIconClasses(tier)"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l5 5l10 -10" />
            </svg>
            {{ feature }}
          </li>
        </ul>
        <a
          :href="tier.href"
          :aria-describedby="tier.id"
          :class="getTierLinkClasses(tier)"
        >
          Get started today
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tiers: [
        {
          name: "Indie",
          id: "tier-indie",
          href: "#",
          priceMonthly: "$49",
          description:
            "The perfect plan if you're just getting started with our product.",
          features: [
            "1 Contract",
            "Unlimited Collaborators",
            "Basic analytics",
            "Basic support"
          ],
          featured: false
        },
        {
          name: "Professional",
          id: "tier-professional",
          href: "#",
          priceMonthly: "$299",
          description: "Dedicated support and infrastructure for your company.",
          features: [
            "Unlimited Contracts",
            "Unlimited Collaborators",
            "Advanced analytics",
            "24-hour support response time",
            "Feature pitch",
            "Feature pitch",
            "Feature pitch"
          ],
          featured: true
        }
      ]
    };
  },
  methods: {
    getTierClasses(tier, tierIdx) {
      return [
        tier.featured
          ? "relative bg-white border-2 border-nvoko shadow-2xl shadow-nvoko"
          : "bg-white/60 sm:mx-8 lg:mx-0 ring-1 ring-gray-200",
        tier.featured
          ? ""
          : tierIdx === 0
          ? "rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl"
          : "sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none",
        "rounded-3xl p-8 sm:p-10"
      ];
    },
    getTierHeaderClasses(tier) {
      return [
        tier.featured ? "text-nvoko" : "text-nvoko",
        "text-base font-semibold leading-7"
      ];
    },
    getTierPriceClasses(tier) {
      return [
        tier.featured ? "text-nvoko" : "text-white",
        "text-5xl font-bold tracking-tight"
      ];
    },
    getTierPriceSuffixClasses(tier) {
      return [tier.featured ? "text-gray-900" : "text-gray-50", "text-base"];
    },
    getTierDescriptionClasses(tier) {
      return [
        tier.featured ? "text-gray-900" : "text-gray-100",
        "mt-2 mb-4 text-base leading-7"
      ];
    },
    getTierListClasses(tier) {
      return [
        tier.featured ? "text-gray-900" : "text-gray-200",
        "mt-8 space-y-3 text-sm leading-6 sm:mt-10"
      ];
    },

    getCheckIconClasses(tier) {
      return [tier.featured ? "text-nvoko" : "text-nvoko", "h-6 w-5 flex-none"];
    },
    getTierLinkClasses(tier) {
      return [
        tier.featured
          ? "bg-nvoko text-white shadow-sm hover:bg-nvoko focus-visible:outline-nvoko"
          : "text-nvoko ring-1 ring-inset ring-nvoko hover:ring-nvoko focus-visible:outline-nvoko",
        "mt-8 block rounded-md transition py-2.5 px-3.5 text-center transform hover:-translate-y-1 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
      ];
    }
  }
};
</script>
<style scoped></style>
