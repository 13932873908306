<template>
  <aside class="sidebar">
    <div class="content">
      <div class="logo">
        <img src="../assets/logo.svg" />
      </div>

      <Dropdown class="w-full">
        <template v-slot:button="{ toggleDropdown, isDropdownOpen }">
          <button
            @click="toggleDropdown"
            class="flex justify-between artist"
            style="width: 280px;"
          >
            <div class="flex items-center justify-start flex-1">
              <img
                class="w-full h-full object-cover"
                v-if="artist.avatar_url"
                :src="artist.avatar_url"
                alt=""
              />
              <div v-else class="initials">{{ artist.name | initials }}</div>
              <span class="name">{{ artist.name }}</span>
              <span class="pro" v-if="isPro">PRO</span>
            </div>
            <span class="ml-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-down"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                :style="isDropdownOpen ? 'transform: rotate(180deg)' : ''"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 9l6 6l6 -6" />
              </svg>
            </span>
          </button>
        </template>

        <template v-slot:items="{ toggleDropdown }">
          <p class="px-2 mb-2 text-gray-400">User</p>
          <router-link
            style="margin-bottom: 3px;"
            :to="{ name: 'Settings', query: { type: 'user' } }"
            v-slot="{ href, navigate }"
          >
            <div
              @click="
                () => {
                  navigate();
                  toggleDropdown();
                }
              "
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Dashboard.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  User Settings
                </a>
              </div>
            </div>
          </router-link>
          <hr class="my-2 border-gray-700" />

          <p class="px-2 mb-2 text-gray-400">Artist</p>
          <router-link
            style="margin-bottom: 3px;"
            :to="{ name: 'Settings', query: { type: 'artist' } }"
            v-slot="{ href, navigate }"
          >
            <div
              @click="
                () => {
                  navigate();
                  toggleDropdown();
                }
              "
              class="menu-item"
            >
              <div class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-microphone-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ffffff90"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M15 12.9a5 5 0 1 0 -3.902 -3.9" />
                  <path
                    d="M15 12.9l-3.902 -3.899l-7.513 8.584a2 2 0 1 0 2.827 2.83l8.588 -7.515z"
                  />
                </svg>
              </div>
              <div class="name">
                <a :href="href">
                  Artist Settings
                </a>
              </div>
            </div>
          </router-link>
          <div
            class="menu-item"
            style="margin-bottom: 3px;"
            @click="
              () => {
                showModal = 'switch-artist';
                toggleDropdown();
              }
            "
          >
            <div class="icon">
              <img src="../assets/icons/Switch.svg" />
            </div>
            <div class="name">
              Switch Artist
            </div>
          </div>

          <hr class="my-2 border-gray-700" />
          <div
            style="margin-bottom: 0px;"
            class="menu-item"
            @click="
              () => {
                logout();
                toggleDropdown();
              }
            "
          >
            <div class="icon">
              <img src="../assets/icons/Logout.svg" />
            </div>
            <div class="name">
              Log out
            </div>
          </div>
        </template>
      </Dropdown>

      <div class="menu">
        <template v-if="isSettings">
          <div class="menu-item back" @click="isSettings = false">
            <div class="icon flip">
              <img src="../assets/icons/Arrow-right-alt.svg" />
            </div>
            <div class="name">
              Main Menu
            </div>
          </div>
          <div class="section-title">Artist</div>
          <router-link
            :to="{ name: 'ArtistProfile' }"
            v-slot="{ href, navigate, isActive }"
          >
            <div
              @click="navigate"
              :class="[isActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Dashboard.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Settings
                </a>
              </div>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'TeamManagement' }"
            v-slot="{ href, navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[isExactActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Feedback.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  My Team
                </a>
              </div>
            </div>
          </router-link>
          <div class="section-title">User</div>
          <router-link
            :to="{ name: 'UserProfile' }"
            v-slot="{ href, navigate, isActive }"
          >
            <div
              @click="navigate"
              :class="[isActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Dashboard.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Settings
                </a>
              </div>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Subscription' }"
            v-slot="{ href, navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[isExactActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Feedback.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Subscription
                </a>
              </div>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Payouts' }"
            v-slot="{ href, navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[isExactActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Feedback.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Payouts
                </a>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else>
          <!-- <router-link
            :to="{ name: 'Deals' }"
            v-slot="{ href, navigate, isActive }"
          >
            <div
              @click="navigate"
              :class="[isActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Deals.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Deals
                </a>
              </div>
              <div class="extra">
                <div class="notifications">
                  <img src="../assets/icons/Bell.svg" />
                  <span class="notifications-count">1</span>
                </div>
              </div>
            </div>
          </router-link> -->
          <div class="menu-item create-deal" @click="showModal = 'add-song'">
            <div class="icon">
              <img src="../assets/icons/CreateDeal.svg" />
            </div>
            <div class="name">
              Create Deal
            </div>
          </div>
          <router-link
            :to="{ name: 'Songs' }"
            v-slot="{ href, navigate, isActive }"
          >
            <div
              @click="navigate"
              :class="[isActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Catalog.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Songs
                </a>
              </div>
              <!--
                <div class="extra">
                <div class="notifications">
                  <img src="../assets/icons/Bell.svg" />
                  <span class="notifications-count">1</span>
                </div>
              </div>
              -->
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Catalog' }"
            v-slot="{ href, navigate, isActive }"
          >
            <div
              @click="navigate"
              :class="[isActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.12552 14.251C3.19673 14.251 0 11.0543 0 7.12552C0 3.19673 3.19673 0 7.12552 0C11.0543 0 14.251 3.19673 14.251 7.12552C14.251 11.0543 11.0543 14.251 7.12552 14.251ZM7.12552 1.12508C3.81703 1.12508 1.12508 3.81703 1.12508 7.12552C1.12508 10.434 3.81703 13.126 7.12552 13.126C10.434 13.126 13.126 10.434 13.126 7.12552C13.126 3.81703 10.434 1.12508 7.12552 1.12508Z"
                    :fill="isActive ? '#FF035A' : '#ffffff'"
                  />
                  <path
                    d="M17.4398 17.9992C17.2958 17.9992 17.1518 17.9444 17.0422 17.8342L11.5594 12.3513C11.3396 12.1315 11.3396 11.7752 11.5594 11.5554C11.7791 11.3357 12.1354 11.3357 12.3552 11.5554L17.8381 17.0384C18.0578 17.2581 18.0578 17.6144 17.8381 17.8342C17.7278 17.9444 17.5838 17.9992 17.4398 17.9992Z"
                    :fill="isActive ? '#FF035A' : '#ffffff'"
                  />
                </svg>
              </div>
              <div class="name">
                <a :href="href">
                  Catalog
                </a>
              </div>
              <!--
                <div class="extra">
                <div class="notifications">
                  <img src="../assets/icons/Bell.svg" />
                  <span class="notifications-count">1</span>
                </div>
              </div>
              -->
            </div>
          </router-link>
          <!-- <router-link
            :to="{ name: 'Feedback' }"
            v-slot="{ href, navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[isExactActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Feedback.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Feedback
                </a>
              </div>
            </div>
          </router-link> -->
          <router-link
            v-if="false"
            :to="{ name: 'Wallet' }"
            v-slot="{ href, navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[isExactActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/Wallet.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Wallet
                </a>
              </div>
              <!--
              <div class="extra">
                <p class="wallet-balance">$450</p>
              </div>
              -->
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Contacts' }"
            v-slot="{ href, navigate, isActive }"
          >
            <div
              @click="navigate"
              :class="[isActive && 'active']"
              class="menu-item"
            >
              <div class="icon">
                <img src="../assets/icons/User.svg" />
              </div>
              <div class="name">
                <a :href="href">
                  Collaborators
                </a>
              </div>
            </div>
          </router-link>

          <!--
          <div class="menu-item">
            <div class="icon">
              <img src="../assets/icons/Support.svg" />
            </div>
            <div class="name">
              Help Portal
            </div>
          </div>
          -->
        </template>
      </div>
      <!-- <div class="user">
        <img
          v-if="user.avatar_url"
          :src="user.avatar_url"
          class="avatar-url"
          alt=""
        />
        <div v-else class="initials">
          {{ `${user.first_name} ${user.last_name}` | initials }}
        </div>
        <div class="details">
          <b>{{ `${user.first_name} ${user.last_name}` }}</b>
          <span v-if="company">{{ company.name }}</span>
        </div>
      </div> -->
      <div class="w-full px-5 mt-auto logout">
        <!--
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          pretium, augue at aliquam viverra, neque nisi gravida arcu.
        </span>
        -->
        <!-- <button class="w-full menu-item" @click="logout">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout-2" width="24" height="24"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2" />
              <path d="M15 12h-12l3 -3" />
              <path d="M6 15l-3 -3" />
            </svg>
          </div>
          <div class="name">
            Logout
          </div>
        </button> -->
      </div>
      <div class="flex-wrap w-full bottom">
        <img class="n-logo" src="../assets/logo.png" /> &copy; 2024.
        <a href="/terms-of-use" class="link">Terms.</a>
        <a
          href="https://app.termly.io/document/privacy-policy/edbf75fb-d7c1-4c85-81b7-8f9a585e29d5"
          class="link"
          >Privacy.</a
        >
      </div>
    </div>

    <SwitchArtist
      v-if="showModal === 'switch-artist'"
      @close="showModal = ''"
    />
    <AddSong
      :my-artist="artist"
      add-new-required
      v-if="showModal === 'add-song'"
      @close="showModal = ''"
    />
  </aside>
</template>

<script>
import SwitchArtist from "@/components/modals/SwitchArtist";
import AddSong from "@/components/modals/AddSong";
import Dropdown from "./Dropdown.vue";

export default {
  name: "Sidebar",
  components: { SwitchArtist, AddSong, Dropdown },
  data() {
    return {
      isSettings: false,
      showModal: ""
    };
  },
  mounted() {
    // this.isSettings = this.$route.path.includes("/settings");
  },
  watch: {
    // "$route.path"(val) {
    //   this.isSettings = val.includes("/settings");
    // }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  computed: {
    artist() {
      return this.$store.getters["account/getArtist"];
    },
    user() {
      return this.$store.getters["account/getUser"];
    },
    company() {
      return this.$store.getters["account/getCompany"];
    },
    isPro() {
      return !!this.user?.subscription?.artist_subscriptions.find(
        ({ artist_id }) => artist_id === this.artist?.id
      );
    }
  },
  methods: {
    logout() {
      this.$store.commit("account/logout");
      window.location.href = "/";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.sidebar {
  height: calc(100vh - 54px);
  background-color: #181818;
  border-radius: 20px 15px 15px 20px;
  position: relative;
  z-index: 100;

  .menu-item {
    height: 48px;
    display: flex;
    color: $white;
    padding: 15px 24px;
    margin-bottom: 16px;
    cursor: pointer;

    .icon {
      display: flex;
      align-items: center;

      img {
        height: 18px;
        width: 18px;
      }
    }

    .flip {
      transform: rotate(180deg);
    }

    .name {
      flex: 1;
      margin-left: 24px;
      display: flex;
      align-items: center;
      font-weight: 500;

      a {
        color: $white;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.2475px;
        text-decoration: none;
      }
    }

    .extra {
      display: flex;
      align-items: center;

      .notifications {
        display: flex;

        img {
          width: 16px;
          height: 16px;
        }

        .notifications-count {
          font-size: 10px;
          line-height: 12px;
          margin-left: -3px;
          margin-top: -5px;
        }
      }
    }

    &:hover {
      background-color: $text-additional;
      border-radius: 4px;
    }

    &.active {
      background-color: $white;
      border-radius: 4px;

      .icon {
        img {
          filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(335deg);
        }
      }

      .name {
        color: $accent;

        a {
          color: $accent;
        }
      }
    }
  }

  .content {
    margin: 15px 0;
    position: fixed;
    height: calc(100vh - 84px);
    width: 279px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .logo {
      margin-top: 25px;
      margin-bottom: 33px;
      display: flex;
      justify-content: center;

      img {
        height: 28px;
      }
    }

    .avatar-url {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      border: 1px $accent solid;
    }

    .initials {
      background-color: $black;
      border: 1px solid $accent;
      color: #fff;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      font-size: 26px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: sans-serif;
    }

    .artist {
      display: flex;
      align-items: center;
      padding: 0 27px;

      img {
        width: 52px;
        height: 52px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 5px rgba(255, 255, 255, 0.25));
        border-radius: 25px;
      }

      span {
        margin-left: 16px;
        margin-right: 5px;
        color: $white;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
        letter-spacing: -0.2475px;
      }

      .name {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      .pro {
        background-color: $accent;
        line-height: 12px;
        font-size: 12px;
        padding: 1px 2px 2px;
        border-radius: 3px;
        margin-top: 6px;
        margin-left: auto;
      }
    }

    .menu {
      margin-top: 32px;
      padding: 0 20px;

      .divider {
        height: 1px;
        width: 90%;
        margin: 1rem 5%;
        background-color: white;
      }

      .create-deal {
        border: 1px solid $white;
        border-radius: 4px;
      }

      .create-deal:hover {
        background: $accent !important;
      }

      .menu-item {
        height: 48px;
        display: flex;
        color: $white;
        padding: 15px 24px;
        margin-bottom: 16px;
        cursor: pointer;

        .icon {
          display: flex;
          align-items: center;

          img {
            height: 18px;
            width: 18px;
          }
        }

        .flip {
          transform: rotate(180deg);
        }

        .name {
          flex: 1;
          margin-left: 24px;
          display: flex;
          align-items: center;
          font-weight: 500;

          a {
            color: $white;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.2475px;
            text-decoration: none;
          }
        }

        .extra {
          display: flex;
          align-items: center;

          .notifications {
            display: flex;

            img {
              width: 16px;
              height: 16px;
            }

            .notifications-count {
              font-size: 10px;
              line-height: 12px;
              margin-left: -3px;
              margin-top: -5px;
            }
          }
        }

        &:hover {
          background-color: $text-additional;
          border-radius: 4px;
        }

        &.active {
          background-color: $white;
          border-radius: 4px;

          .icon {
            img {
              filter: brightness(0.5) sepia(1) saturate(10000%)
                hue-rotate(335deg);
            }
          }

          .name {
            color: $accent;

            a {
              color: $accent;
            }
          }
        }
      }

      .section-title {
        height: 48px;
        display: flex;
        color: $white;
        padding: 5px 24px 13px;
        font-weight: 500;
        font-size: 1.1rem;
      }

      .back {
        border: 1px #616161 solid;
        border-radius: 4px;
      }
    }

    .bottom {
      display: flex;
      padding: 1rem 1.5rem;
      align-items: center;
      // margin-top: auto;
      color: #fff;

      .n-logo {
        height: 24px;
        margin-right: 10px;
        display: inline-block;
      }

      a {
        margin: 2px;
        color: #eee;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .user {
      display: flex;
      padding: 1rem 1.5rem;
      align-items: center;
      margin-top: auto;

      .avatar-url {
        height: 45px;
        width: 45px;
      }

      .initials {
        width: 45px;
        height: 45px;
        font-size: 20px;
      }

      .details {
        margin-left: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span,
        b {
          color: $white;
          line-height: 21px;
          text-align: center;
          letter-spacing: -0.2475px;
        }

        b {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
