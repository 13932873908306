<template>
  <div class="inputs">
    <div class="form-group names" v-show="!noUserInfo">
      <input
        v-model="value.firstName"
        id="first_name"
        type="text"
        class="text-input dark"
        placeholder="First name"
        :hidden="noUserInfo"
      />
      <input
        v-model="value.lastName"
        id="last_name"
        type="text"
        class="text-input dark"
        placeholder="Last name"
        :hidden="noUserInfo"
      />
    </div>
    <div class="form-group" v-show="!noUserInfo">
      <input
        v-model="value.email"
        id="email"
        type="email"
        class="text-input dark"
        placeholder="Email address"
        :hidden="noUserInfo"
      />
    </div>
    <div class="form-group">
      <input
        v-model="value.talentName"
        type="text"
        class="text-input dark"
        placeholder="Artist/Producer Name*"
      />
    </div>
    <div class="form-group">
      <AddressInput v-model="value.address" title="Address*" />
    </div>
    <div class="form-group" v-show="!notSigned">
      <CustomSelect
        :options="[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' }
        ]"
        @setValue="val => (value.recording_agreement = val)"
        class="rep-selector dark left"
        :val="value.recording_agreement"
        default="Has recording agreement?"
        noMargin
      />
    </div>
    <div class="form-group" v-show="!notSigned">
      <CustomSelect
        :options="[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' }
        ]"
        @setValue="val => (value.recording_agreement_third_party = val)"
        class="rep-selector dark left"
        :val="value.recording_agreement_third_party"
        default="Has recording agreement with 3rd party?"
        noMargin
      />
    </div>
    <div class="form-group">
      <input
        v-model="value.PROAffiliation"
        type="text"
        class="text-input dark"
        placeholder="PRO Affiliation*"
      />
    </div>
    <div class="form-group">
      <input
        v-model="value.songwriterIPINo"
        type="text"
        class="text-input dark"
        placeholder="Songwriter IPI Number*"
      />
    </div>
    <div class="form-group">
      <input
        v-model="value.publishingAdmin"
        type="text"
        class="text-input dark"
        placeholder="Publisher"
      />
    </div>
    <div class="form-group">
      <input
        v-model="value.publisherIPINo"
        type="text"
        class="text-input dark"
        placeholder="Publisher IPI Number"
      />
    </div>
  </div>
</template>

<script>
import AddressInput from "@/components/AddressInput.vue";
import CustomSelect from "@/components/Select.vue";

export default {
  name: "AddArtistForm",
  components: { AddressInput, CustomSelect },
  props: {
    value: {
      type: Object,
      required: true
    },
    noUserInfo: {
      type: Boolean,
      default: false
    },
    notSigned: {
      type: Boolean,
      default: false
    },
    companyName: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      repOptions: [
        { value: "label", label: "Label" },
        { value: "loan_out_company", label: "Loan-out Company" }
      ]
    };
  },
  computed: {
    signedToOptions() {
      return [
        { value: "none", label: "No" },
        { value: "to_company", label: `Yes (to ${this.companyName})` },
        { value: "to_third_party", label: "Yes (to third-party label)" }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.inputs {
  min-width: 20rem;
  margin-bottom: 32px;
  .form-group {
    margin-bottom: 18px;
    input {
      @include details-light();
      color: $white;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.22px;
      color: #fcfffc;
    }
  }
  .names {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    input {
      width: 48%;
    }
  }
}
</style>
