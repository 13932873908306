<template>
  <input
    ref="autocompleteInput"
    v-model="address"
    type="text"
    class="text-input"
    :class="{
      success: valid === true,
      error: valid === false,
      light: theme === 'light',
      dark: theme !== 'light'
    }"
    :placeholder="title"
    autocomplete="off"
  />
</template>

<script>
/*
import SmartySDK from "smartystreets-javascript-sdk";

const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usStreet.Lookup;

const credentials = new SmartyCore.SharedCredentials("134787026403138222");

const smartyClientBuilder = new SmartyCore.ClientBuilder(
  credentials
).withLicenses(["us-core-cloud"]);
const smartyClient = smartyClientBuilder.buildUsStreetApiClient();
*/
export default {
  name: "AddressInput",
  props: ["value", "title", "theme"],
  data() {
    return {
      address: "",
      valid: undefined
    };
  },
  mounted() {
    this.address = `${this.value || ""}`;

    this.$nextTick(() => {
      // eslint-disable-next-line no-undef
      const autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocompleteInput
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        console.log(place);
        this.address = place.formatted_address;
      });
    });
  },
  watch: {
    address(val) {
      this.$emit("input", val ? this.address : undefined);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

input {
  @include details-light();

  &.dark {
    color: $white;
  }

  &.success {
    border: 1px #00e000 solid;
  }

  &.error {
    border: 1px #ff0000 solid;
  }
}

span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.22px;
  color: #fcfffc;
}
</style>
