import ADD_SONG_HISTORY from "@/api/mutations/ADD_SONG_HISTORY.gql";

export default {
  methods: {
    trackContractHistory(song_id, artist_id, type) {
      const songHistoryDict = {
        createdSong: {
          description: "Created song",
          icon: "plus"
        },
        createdContract: {
          description: "Created contract",
          icon: "plus"
        },
        updatedTerms: {
          description: "Updated deal terms",
          icon: "pen"
        },
        updatedCollaborators: {
          description: "Updated collaborators",
          icon: "pen"
        },
        acknowledgedTermChanges: {
          description: "Acknowledged Term Changes",
          icon: "check"
        },
        approvedTerms: {
          description: "Approved deal terms",
          icon: "check"
        },
        waitingForApproval: {
          description: "Waiting on approval",
          icon: "clock"
        },
        waitingForSignature: {
          description: "Waiting on signature",
          icon: "clock"
        },
        signed: {
          description: "Signed contract",
          icon: "sign"
        },
        purchased: {
          description: "Paid for the contract",
          icon: "cash"
        }
      };

      const data = {
        song_id: song_id,
        action_by: artist_id,
        description: songHistoryDict[type].description,
        icon: songHistoryDict[type].icon
      };

      return this.$apollo.mutate({
        mutation: ADD_SONG_HISTORY,
        variables: { data }
      });
    }
  }
};
